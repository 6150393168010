import { Route, Routes } from 'react-router-dom'
import styles from './Body.module.css'
import ContactUs from 'routes/ContactUs/ContactUs'
import Home from 'routes/Home/Home'
import Pricing from 'routes/Pricing/Pricing'
import FAQ from 'routes/FAQ/FAQ'
import AboutUs from 'routes/AboutUs/AboutUs'
import HowItWorks from 'routes/HowItWorks/HowItWorks'
import Veterinarians from 'routes/Veterinarians/Veterinarians'
import TermsOfService from 'routes/TermsOfService/TermsOfService'
import PrivacyPolicy from 'routes/PrivacyPolicy/PrivacyPolicy'
import UnknownRoute from 'routes/UnknownRoute/UnknownRoute'
import Home2 from 'routes/Home/Home2'

const Body = () => {
    return (
        <div className={styles.body}>
            <Routes>
                <Route path='/' element={<Home2 />}/>
                {/* <Route path='/how-it-works' element={<HowItWorks />}/> */}
                {/* <Route path='/pricing' element={<Pricing />}/>
                <Route path='/faq' element={<FAQ />}/>
                <Route path='/about-us' element={<AboutUs />}/> */}
                <Route path='/contact-us' element={<ContactUs />}/>
                <Route path='/veterinarians' element={<Veterinarians />}/>
                {/* <Route path='/terms-of-service' element={<TermsOfService />}/>
                <Route path='/privacy-policy' element={<PrivacyPolicy />}/> */}
                <Route path='*' element={<UnknownRoute />}/>
            </Routes>
        </div>
    )
}

export default Body