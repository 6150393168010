import styles from './Header.module.css'
import { Link, useNavigate } from 'react-router-dom'
import logo from 'assets/images/logos/1024logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleEllipsisVertical } from '@fortawesome/pro-duotone-svg-icons'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import { useContext } from 'react'
import { GlobalContext, useGlobalContext } from 'common/context/GlobalContext'

interface Props {
    toggleNav: () => void,
    setNavOpen: (openNav: boolean) => void,
    isNavOpen: boolean
}

const Header = ({ isNavOpen, toggleNav, setNavOpen }: Props) => {
    const navigate = useNavigate()
    const context = useContext(GlobalContext)
    
    const handleClick = () => {
        setNavOpen(false)
        navigate('/')
    }

    const goToWebApp = () => {
        window.open('https://app.phauna.io', '_blank')
    }

    return (
        <header className={styles.header}>
            <div className={styles.innerHeaderContainer}>
                <div className={styles.leftButtonContainer}>
                    <button
                        className={styles.logoButton}
                        onClick={handleClick}
                    >
                        <img className={styles.logo} src={logo} alt='Phauna Logo'/>
                        <p className={styles.name}>Phauna</p>
                    </button>
                </div>
                <Link
                    className={styles.link}
                    to='/'
                    onClick={() => {setNavOpen(false)}}
                >
                    Overview
                </Link>
                {/* <Link
                    className={styles.link}
                    to='/how-it-works'
                    onClick={() => {setNavOpen(false)}}
                >
                    How It Works
                </Link> */}
                {/* <Link
                    className={styles.link}
                    to='/veterinarians'
                    onClick={() => {setNavOpen(false)}}
                >
                    Veterinarians
                </Link> */}
                {/* <Link
                    className={styles.link}
                    to='/pricing'
                    onClick={toggleNav}
                >
                    Pricing
                </Link>
                <Link
                    className={styles.link}
                    to='/faq'
                    
                >
                    FAQ
                </Link> */}
                {/* <Link className={styles.link} to='/about-us'>About Us</Link> */}
                
                <Link className={styles.link} onClick={() => {setNavOpen(false)}} to='/contact-us'>Contact Us</Link>
                
                <div className={styles.rightButtonContainer}>
                    <button
                        className={styles.vetButton}
                        onClick={() => { setNavOpen(false); context!.setIsVet(!context!.isVet) }}
                    >
                        {context!.isVet ? 'Not a Vet' : 'I\'m a Vet'}
                    </button>
                    <button
                        className={styles.getStartedButton}
                        onClick={goToWebApp}
                    >
                        Get Started
                    </button>
                    <button
                    className={styles.navButton}
                    style={{ transform: isNavOpen ? 'rotate(90deg)' : 'none' }}
                    onClick={toggleNav}
                >
                    <FontAwesomeIcon icon={faBars} style={{color: 'black', fontSize: '1rem', padding: '0px', margin: '0px'}}/>
                </button>
                </div>
                
               
            </div>
        </header>
    )
}

export default Header